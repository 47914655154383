import { useNavigate } from "react-router-dom";

export default function PostCard({ post }) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`posts/${post.id}`);
  }

  return (
    <div className="blog" onClick={handleClick}>
      <div className="flex">
        <div className="info">
          <span className="ident">tilføjet den: {post.date}</span>
          <h1>Praktikforløb {post.title}</h1>
        </div>
        <section className="card">
          <div className="posts">
            <p>{post.story.substring(0, 400)}...</p>
            <h2>Læs mere</h2>
          </div>
        </section>
        <br></br> <br></br>
      </div>
      <hr></hr>
    </div>
  );
}
