import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function UpdatePage() {
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const params = useParams();

  // console.log(`params ${params.postId}`);

  const url = `https://blog-fd991-default-rtdb.europe-west1.firebasedatabase.app/blog/${params.postId}.json`;

  useEffect(() => {
    async function getPost() {
      const response = await fetch(url);
      const data = await response.json();
      setPost(data);
    }
    getPost();
  }, [url]);
  function handleClick() {
    navigate(`/`);
  }

  return (
    <article className="page">
      <h1 id="title">Magnus's blog</h1>
      <hr id="line"></hr>
      <div className="blogposts">
        <div className="blog" onClick={handleClick}>
          <div className="flex">
            <div className="info">
              <span className="ident">tilføjet den: {post.date}</span>
              <h1>Praktikforløb {post.title}</h1>
            </div>
            <section className="card">
              <div className="posts">
                <p dangerouslySetInnerHTML={{ __html: post.story }} />
                <h2>Gå tilbage</h2>
              </div>
            </section>
          </div>
          <hr></hr>
        </div>
      </div>
    </article>
  );
}
