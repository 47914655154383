import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import UpdatePage from "./pages/UpdatePage";
import Nav from "./components/Nav";

function App() {
  return (
    <main>
      <Nav />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/posts/:postId" element={<UpdatePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </main>
  );
}

export default App;
