import { useEffect, useState } from "react";
import PostCard from "../components/Postcard";

export default function HomePage() {
  const [posts, setPosts] = useState([]);
  const [isPosts, setIsPosts] = useState(true);

  const url =
    "https://blog-fd991-default-rtdb.europe-west1.firebasedatabase.app/blog.json";

  useEffect(() => {
    async function getPosts() {
      const response = await fetch(url);
      const data = await response.json();
      if (data !== null) {
        const postsArray = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .reverse();
        setPosts(postsArray); // Update "posts" object array list
      } else {
        setIsPosts(false);
      }
    }
    getPosts();
  }, []);

  return (
    <article className="page">
      <h1 id="title">Magnus's blog</h1>
      <hr id="line"></hr>
      <div className="blogposts">
        {isPosts ? (
          <div className="flexbox">
            {posts.map((post) => (
              <PostCard key={post.id} post={post} />
            ))}
          </div>
        ) : (
          <p>Nothing to show</p>
        )}
      </div>
    </article>
  );
}
